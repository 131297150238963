.active,
.inactive:hover {
    padding: 8px !important;
    border-radius: 12px !important;
    background-color: rgba(17, 25, 39, 0.04) !important;
    color: rgba(108, 115, 127, 1) !important;
}

.paper {
    max-width: 252px !important;
    width: 100%;
    box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.08) !important;
    margin-top: 9px;
}

.menu {
    padding: 16px 0 !important;
    border-radius: 8px;

    .menuItem {
        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }
}
